import React from "react";
import ReactLoading from 'react-loading'
import { Link, useLocation, useNavigate } from "react-router-dom";
import Modal from 'react-modal'
import "./css/Form.css"
import './css/global.css'
import "./css/CategoryList.css"
import voltar from "../images/voltar-icon.png"
import excluir from '../images/excluir-icon.png'
import { useState } from "react";
import { useEffect } from "react";
import CategoriesService from "../services/CategoryService";
import LoginService from "../services/LoginService";

export default function ListCategory() {
  const navigate = useNavigate()
  const location = useLocation()

  const [categories, setCategories] = useState([])
  const [categoryType, setCategoryType] = useState(location.state?.categoryType ?? 'delivery')
  const [modalOpen, setModalOpen] = useState(false)
  const [modalMessage, setModalMessage] = useState("")
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)
  const [idToDelete, setIdToDelete] = useState('')
  const [isAuthenticate, setIsAuthenticate] = useState(false)
  const [loading, setLoading] = useState(false)

  const categoryService = new CategoriesService()

  useEffect(() => {
    const loginService = new LoginService()
    const checkUser = async () => {
      try {
        await loginService.checkLoggedUser()
        setIsAuthenticate(true)
      } catch (e) {
        setIsAuthenticate(false)
        setModalMessage(e.message)
        setModalOpen(true)
      }
    }
    checkUser()
  }, [])

  const closeModal = () => {
    if(modalOpen) {
      setModalOpen(false)
      navigate('/login')
    } else {
      setIdToDelete('')
      setConfirmationModalOpen(false)
    }
  }

  useEffect(() => {
    const getCategories = async() => {
      setLoading(true)
      const data = await categoryService.fectchAll()
      setCategories(data)
      setLoading(false)
    }
    getCategories()
  }, [])

  const deleteConfirmation = (id, name) => {
    setModalMessage('Tem certeza que deseja excluir a categoria ' + name + '?')
    setConfirmationModalOpen(true)
    setIdToDelete(id)
  }

  const deleteCategory = async () => {
    try {
      setLoading(true)
      await categoryService.delete(idToDelete)
      
    }finally {
      setConfirmationModalOpen(false)
      setLoading(false)
      navigate(0)
    }
  }

  return (
    isAuthenticate ? (
      <>
        <div className="form-header">
          <Link to="/admin/" className="back-section">
            <img src={voltar} alt="voltar" />
          </Link>
          <h3>EDITAR CATEGORIA</h3>
        </div>
        {
          !!loading ? (
            <div className="loading-container">
              <ReactLoading className="loading" type="spin" />
            </div>
          ) : (
            <>
              <div className="form-container">
                <div className="tabs">
                  <div
                    className={`tab ${categoryType === 'delivery' && 'selected'}`}
                    onClick={() => setCategoryType('delivery')}
                  >
                    DELIVERY
                  </div>
                  <div
                    className={`tab ${categoryType === 'service' && 'selected'}`}
                    onClick={() => setCategoryType('service')}
                  >
                    PRESTAÇÃO DE SERVIÇOS
                  </div>
                </div>
                <div className="list">
                  {
                    categories[categoryType].length === 0 ? (
                      <p style={{ display: 'flex', justifyContent: 'flex-start', fontSize: '16px' }}>Nenhuma categoria cadastrada.</p>
                    ) : (
                      categories[categoryType].map(category => (
                        <table style={{width: '100%'}}>
                          <tbody>
                            <td key={category.id} className="list-item" onClick={() => navigate("/admin/categoria/" + category.id, { state: { categoryType }})}>
                              {category.name.toUpperCase()}
                            </td>
                            <td style={{width: '20%'}}>
                              <img src={excluir} alt="excluir" className="trash" onClick={() => deleteConfirmation(category.id, category.name)} />
                            </td>
                          </tbody>
                        </table>
                      ))
                    )
                  }
                </div>
              </div>
              <Modal
                isOpen={confirmationModalOpen}
                onRequestClose={closeModal}
                style={{
                  content: {
                    height: '100px',
                    maxWidth: '300px',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }
                }}
              >
                {modalMessage}
                <div className="two-buttons-modal-section">
                  <button onClick={deleteCategory} className="modal-button">OK</button>
                  <button onClick={closeModal} className="cancel-button">Cancelar</button>
                </div>
              </Modal>
            </>
          )
        }
      </>
    ) : (
        <Modal
          isOpen={modalOpen}
          onRequestClose={closeModal}
          style={{
            content: {
              height: '100px',
              maxWidth: '300px',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }
          }}
        >
          {modalMessage}
          <button onClick={closeModal} className="modal-button">OK</button>
        </Modal>
    )
  )
}