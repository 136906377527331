import CustomerItem from "../components/CustomerItem"
import ReactLoading from 'react-loading'
import logo from "../images/logo.png"
import voltar from "../images/voltar-icon.png"
import whatsapp from "../images/whats.png"
import "./css/CustomerList.css"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import CustomerService from "../services/CustomerService"

export default function CustomerList() {
  const {categoryId, category} = useParams()
  const [customers, setCustomers] = useState([])
  const [loading, setLoading] = useState(true)

  const customerService = new CustomerService()

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const getCustomers = async () => {
      const data = await customerService.fetchAllByCategoryId(categoryId)
      setCustomers(data)
      setLoading(false)
    }
    getCustomers()
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <div className="container-customer">
      <div className="header-logo">
        <img src={logo} className="logo-customer" alt="logo" />
        <Link className="container-anuncie" to="https://api.whatsapp.com/send?phone=5537999325555">
          <img src={whatsapp} className="whats-logo" alt="anuncie" />
          <div className="anuncie-section">
            <p className="anuncie-text">ANUNCIE!</p>
            <p className="number">37 9 9932 5555</p>
          </div>
        </Link>
      </div>
      <div className="sub-header">
        <div className="back-section" onClick={() => navigate('/', { state: { categoryType: location.state?.categoryType ?? 'delivery' }})}>
          <img src={voltar} alt="voltar" className="icon-voltar" />
          <p>VOLTAR</p>
        </div>
        <div className="category-section">
          {category.toUpperCase()}
        </div>
      </div>
      {
        loading ? (
          <ReactLoading className="loading" type="spin" />
        ) : (
            <div className="list">
              {
                customers.length > 0 ? (
                  customers.map((customer) => {
                    return (
                      <CustomerItem
                        key={customer.id}
                        name={customer.name}
                        description={customer.description}
                        contact={customer.contact.replace('-', '')}
                        logo={`${process.env.REACT_APP_IMAGE_HOST}/${customer.logo}`}
                      />
                    )
                  })
                ) : (
                  <p>Ops! Não encontramos nenhum cliente no momento!</p>
                )
              }
            </div>   
        )
      }
    </div>
  )
}