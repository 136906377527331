import React from "react";
import ReactLoading from 'react-loading'
import "./css/Form.css"
import './css/global.css'
import Modal from 'react-modal'
import voltar from "../images/voltar-icon.png"
import excluir from "../images/excluir-icon.png"
import copy from "../images/copy.png"
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { useState } from "react";
import CustomerService from "../services/CustomerService";
import { useEffect } from "react";
import CategoriesService from "../services/CategoryService";
import LoginService from "../services/LoginService";

export default function AddEditCustomer({action = 'edit | add'}) {

  const [customerFormData, setCustomerFormData] = useState({
    name: '',
    description: '',
    contact: '',
    categories: '',
    logo: '',
    logoPreview: ''
  })
  const [categories, setCategories] = useState([])
  const customerService = new CustomerService()
  const categoryService = new CategoriesService()
  const { id } = useParams()
  const navigate = useNavigate()

  const [modalOpen, setModalOpen] = useState(false)
  const [modalMessage, setModalMessage] = useState("")
  const [isAuthenticate, setIsAuthenticate] = useState(false)
  const [loading, setLoading] = useState(false)


  useEffect(() => {
    const loginService = new LoginService()
    const checkUser = async () => {
      try {
        await loginService.checkLoggedUser()
        setIsAuthenticate(true)
      } catch (e) {
        setIsAuthenticate(false)
        setModalMessage(e.message)
        setModalOpen(true)
      }
    }
    checkUser()
  }, [])

  useEffect(() => {
    const getCustomerData = async (id) => {
      const data = await customerService.getById(id)
      const categoriesSelected = []
      data.categories.forEach(category => {
        categoriesSelected.push({
          value: category.category.id,
          label: category.category.name
        })
      })

      setCustomerFormData({
        name: data.name,
        description: data.description,
        contact: data.contact,
        logo: data.logo,
        categories: categoriesSelected,
        logoPreview: `${process.env.REACT_APP_IMAGE_HOST}/${data.logo}`
      })
    }

    const getCategories = async () => {
      const data = await categoryService.fectchAll()
      setCategories([ ...data.delivery, ...data.service])
    }
    getCategories()

    if(action === 'edit' || !!id) {
      getCustomerData(id)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const closeModal = () => {
    setModalOpen(false)
    !isAuthenticate && navigate('/login')
  }

  const onNameChange = (e) => {
    setCustomerFormData((prevState) => ({
      ...prevState,
      name: e.target.value
    }))
  }

  const onDescriptionChange = (e) => {
    setCustomerFormData((prevState) => ({
      ...prevState,
      description: e.target.value
    }))
  }

  const onContactChange = (e) => {
    setCustomerFormData((prevState) => ({
      ...prevState,
      contact: e.target.value
    }))
  }

  const onLogoChange = (e) => {
    //pega o tamanho do arquivo e transforma em kb
    const fileSize = e.target.files[0].size / 1000000
    if (fileSize > 1.5) {
      alert('O ícone não pode ser maior que 1.5Mb. Sua logo tem '+fileSize+' Mb.')
      document.getElementById('logo').value = ''
    } else {
      setCustomerFormData((prevState) => ({
        ...prevState,
        logo: e.target.files[0],
        logoPreview: URL.createObjectURL(e.target.files[0])
      }))
    }
  }

  const onCategoriesChange = (e) => {
    setCustomerFormData((prevState) => ({
      ...prevState,
      categories: e
    }))
  }
  
  const handleDeleteLogo = () => {
    setCustomerFormData((prevState) => ({
      ...prevState,
      logo: null,
      logoPreview: null
    }))
    document.getElementById('logo').value = ''
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const arrayCategories = []
    customerFormData.categories.forEach(category => {
      arrayCategories.push(category.value)
    });

    try {
      setLoading(true)
      if(!id) {
        await customerService.create(customerFormData.name, customerFormData.description, customerFormData.contact, customerFormData.logo, arrayCategories)
      }else {
        await customerService.update(id, customerFormData.name, customerFormData.description, customerFormData.contact, !!customerFormData.logo?.lastModified ? customerFormData.logo : null, arrayCategories)
      }
      e.preventDefault()
      setLoading(false)
      navigate('/admin/', {
        replace: true
      })
    } catch (e) {
      setModalMessage('Houve um erro ao cadastrar o cliente, verifique as informações no formulário e tente novamente.')
      setModalOpen(true)
      setLoading(false)
    }
  }

  return (
    <>
      {((action !== 'edit' && !!categories) || (action === 'edit' && !!customerFormData.categories)) && (
        <>
          <div className="form-header">
            <Link to={action === 'edit' ? '/admin/cliente/editar' : '/admin'} className="back-section">
              <img src={voltar} alt="voltar" />
            </Link>
            <h3>
              {
                action === 'edit' ? 'EDITAR CLIENTE' : 'CADASTRAR CLIENTE'
              }
            </h3>
          </div>
          <div className="form-container">
            {
              !!loading && (
                <div className="loading-container">
                  <ReactLoading className="loading" type="spin"/>
                </div>
              )
            }
            <form method="post" onSubmit={handleSubmit}>
              <div className="input-section">
                <p>NOME</p>
                <input type="text" name="customer-name" id="customer-name" onChange={onNameChange} value={action === 'edit' ? customerFormData.name : undefined} />
              </div>
              <div className="input-section">
                <p>DESCRIÇÃO</p>
                <p style={{ fontSize: '12px', marginTop: '-5px' }}>Limite: 150 caracteres</p>
                <textarea
                  name="customer-description"
                  onChange={onDescriptionChange}
                  value={action === 'edit' ? customerFormData.description : undefined}
                  maxLength='150'
                />
              </div>
              <div className="input-section">
                <p>WHATSAPP</p>
                <div style={{ display: 'flex', marginTop: '-5px' }}> 
                  <p style={{ fontSize: '12px' }}>Informar link completo: https://wa.me/3199995566</p>
                  <img src={copy} alt="Copiar link whatsapp" width="20" style={{marginLeft: '5px', marginBottom: '5px', cursor: 'pointer'}} onClick={() => navigator.clipboard.writeText('https://wa.me/')}/>
                </div>
                <input type="text" name="customer-contact" onChange={onContactChange} value={action === 'edit' ? customerFormData.contact : undefined} />
              </div>
              <div className="input-section">
                <p>CATEGORIA</p>
                <Select
                  closeMenuOnSelect={false}
                  isMulti
                  defaultValue={action === 'edit' ?
                    customerFormData.categories
                    : undefined}
                  options={
                    categories.map((category) => {
                      return {
                        value: category.id,
                        label: category.name
                      }
                    })
                  }
                  styles={{
                    control: (styles) => ({ ...styles, width: '260px', marginBottom: '20px' }),
                  }}
                  onChange={onCategoriesChange}
                />
              </div>
              <div className="input-section">
                <p className="p-icon">LOGO</p>
                <label htmlFor="logo">ANEXAR</label>
                <input type="file" name="logo" id="logo" onChange={onLogoChange} accept="image/png, image/jpeg, image/jpg" />
                {
                  (customerFormData.logo) && (
                    <>
                      <div className="img-selected logo">
                        <img
                          src={customerFormData.logoPreview}
                          alt={`icon-${customerFormData.name}`}
                          className="upload-logo"
                        />
                        <img src={excluir} className="deleteIcon" alt="excluir-logo" onClick={handleDeleteLogo} />
                      </div>
                    </>
                  )
                }
              </div>
              <button type="submit" name="done" className="form-button">ENVIAR</button>
            </form>
          </div>
        </>
      )}
      <Modal
        appElement={document.getElementById('body')}
        ariaHideApp={false}
        isOpen={modalOpen}
        onRequestClose={closeModal}
        style={{
          content: {
            height: '150px',
            maxWidth: '400px',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            margin: 'auto'
          }
        }}
      >
        {modalMessage}
        <button onClick={closeModal} className="modal-button">OK</button>
      </Modal>
    </>
  )
}