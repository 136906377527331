import React, { useState } from "react";
import "./css/Login.css"
import LoginService from "../services/LoginService";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')
  const [loginError, setLoginError] = useState(false)
  const loginService = new LoginService()
  const navigate = useNavigate()

  const handleChangeLogin = (event) => {
    setLogin(event.target.value)
    if(!!loginError) {
      setLoginError(false)
    }
  }

  const handleChangePassword = (event) => {
    setPassword(event.target.value)
    if (!!loginError) {
      setLoginError(false)
    }
  }

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      handleLogin()
    }
  }

  const handleLogin = async () => {
    try {
      const user = await loginService.login(login, password)
      setLoginError(false)
      localStorage.setItem('SESSION_TOKEN', user.token)
      navigate('/admin')
    } catch(e) {
      setLoginError(true)
    }
  }

  return (
    <div className="container-login">
      <p className="title">LOGIN</p>
      <div className="login-inputs">
        <p>LOGIN</p>
        <input type="text" name="login" onChange={handleChangeLogin}/>

        <p>SENHA</p>
        <input type="password" name="password" onChange={handleChangePassword} onKeyDown={handleKeyDown}/>
        {
          !!loginError && (
            <p style={{color: 'red'}}>Usuário e/ou senha incorretos ou não existem.</p>
          )
        }
      </div>
      <button name="ok" className="login-button" onClick={handleLogin}>OK</button>
    </div>
  )
}