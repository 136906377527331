import axios from "axios";

export default class LoginService {
  async login(login, password) {
    try {
      const user = await axios.post(`${process.env.REACT_APP_API_HOST}/auth/login`, {
        login,
        password
      })
      return user.data
    } catch(e) {
      throw new Error('Login e/ou senha incorretos ou não existem.')
    }
  }

  async checkLoggedUser() {
    try {
      const token = await localStorage.getItem('SESSION_TOKEN')
      if(!token) {
        throw new Error('Usuário não logado')
      }

      const user = await axios.get(`${process.env.REACT_APP_API_HOST}/auth/validate-user`, {
        headers: {
          'Authorization': token
        }
      })

      return {
        token,
        user: user.data
      }
    }catch(e) {
      throw new Error('Token expirado ou inválido.')
    }
  }
}