import CategoryItem from "../components/CategoryItem"
import logo from "../images/logo.png"
import ReactLoading from 'react-loading'
import "./css/CategoryList.css"
import { useEffect, useState } from "react"
import CategoriesService from "../services/CategoryService"
import { Link, useLocation } from "react-router-dom"

export function EmptyList() {
  return (
    <p style={{ marginTop: '50px', padding: '0px 10px 0px 10px' }}>Ops! Não conseguimos encontrar nenhuma categoria no momento.</p>
  )
}

export default function CategoryList() {
  const location = useLocation()

  const [categoryType, setCategoryType] = useState(location.state?.categoryType ?? 'delivery')
  const [categories, setCategories] = useState([])
  const [loading, setLoading] = useState(true)
  const categoriesService = new CategoriesService()

  useEffect(() => {
    const getCategories = async () => {
      const data = await categoriesService.fectchAll()
      setCategories(data)
      setLoading(false)
    }
    getCategories()
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const changeCategoryType = (type) => {
    setCategoryType(type)
  }

  return (
    <div className="container">
      <img src={logo} className="App-logo" alt="logo" />
      {
        loading ? (
          <ReactLoading className="loading" type="spin" />
        ) : (
          <>
            <div className="tabs">
              <div
                className={`tab ${categoryType === 'delivery' && 'selected'}`}
                onClick={() => changeCategoryType('delivery')}
              >
                DELIVERY
              </div>
              <div
                className={`tab ${categoryType === 'service' && 'selected'}`}
                onClick={() => changeCategoryType('service')}
              >
                PRESTAÇÃO DE SERVIÇOS
              </div>
            </div>
            
              {
                categories[categoryType].length === 0 ? (
                  <EmptyList />
                ) : (
                  <div className='categoryGrid'>
                    {
                      categories[categoryType].map((category) => {
                        return (
                          <CategoryItem
                            key={category.id}
                            category={category}
                            categoryType={categoryType}
                          />
                        )
                      })
                    }
                  </div>
                )
              }
         </>
        )
      }
      <Link className="footer" to="https://api.whatsapp.com/send?phone=5537999325555">
        <p style={{marginRight: '20px'}}>ANUNCIE!</p>
        <p>(37) 9 9932-5555</p>
      </Link>
    </div>
  )
}