import React from "react";
import "./CustomerItem.css";
import whatsapp from "../images/whatsapp.png";

export default function CustomerItem({logo, name, description, contact}) {
  return (
    <div className="customer-line">
      <div className="logo-section">
        <img src={logo} alt={name} className="customer-logo" />
      </div>
      <div className="customer-description-section">
        <p className="customer-name">{name}</p>
        <p className="customer-description">{description}</p>
      </div>
      <a href={contact} target="_blank" rel="noreferrer">
        <img src={whatsapp} alt="Whatsapp" className="whats-icon" />
      </a>
    </div>
  )
}