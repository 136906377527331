import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import CategoryList from './pages/CategoryList';
import CustomerList from './pages/CustomerList';
import Login from './pages/Login';
import MainAdmin from './pages/MainAdmin';
import AddEditCategory from './pages/AddEditCategory';
import ListCategory from './pages/ListCategory';
import AddEditCustomer from './pages/AddEditCustomer';
import ListCustomer from './pages/ListCustomer';

const router = createBrowserRouter([
  {
    path: "/",
    element: <CategoryList />,
  },
  {
    path: "/categoria/:category/:categoryId",
    element: <CustomerList />
  },
  {
    path: "/login",
    element: <Login />
  },
  {
    path: "/admin/",
    element: <MainAdmin />
  },
  {
    path: "/admin/categoria",
    element: <AddEditCategory />
  },
  {
    path: "/admin/categoria/:id",
    element: <AddEditCategory action='edit' />
  },
  {
    path: "/admin/cliente",
    element: <AddEditCustomer />
  },
  {
    path: "/admin/cliente/:id",
    element: <AddEditCustomer action='edit' />
  },
  {
    path: "/admin/categoria/editar",
    element: <ListCategory />
  },
  {
    path: "/admin/cliente/editar",
    element: <ListCustomer />
  },
  {
    path: "*",
    element: <CategoryList />
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
