import { useNavigate } from 'react-router-dom'
import './CategoryItem.css'

export default function CategoryItem({category, categoryType}) {

  const navigate = useNavigate()

  const { id, name, icon } = category
  const image = `${process.env.REACT_APP_IMAGE_HOST}/${icon}`

  return (
    <div className='categoryBox' onClick={() => navigate(`/categoria/${name.toLowerCase()}/${id}`, { state: { categoryType }})}>        
        <div className='category'>
          <img src={image} width={80} alt={name} />
        </div>
        <p>{name.toUpperCase()}</p>
    </div>
  )
}