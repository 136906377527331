import React from "react";
import ReactLoading from 'react-loading'
import { Link, useNavigate } from "react-router-dom";
import "./css/Form.css"
import "./css/global.css"
import Modal from 'react-modal'
import voltar from "../images/voltar-icon.png"
import excluir from "../images/excluir-icon.png"
import { useEffect } from "react";
import { useState } from "react";
import CustomerService from "../services/CustomerService";
import LoginService from "../services/LoginService";

export default function ListCustomer() {

  const [customers, setCustomers] = useState([])
  const customerService = new CustomerService()
  const navigate = useNavigate()

  const [modalOpen, setModalOpen] = useState(false)
  const [modalMessage, setModalMessage] = useState("")
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)
  const [idToDelete, setIdToDelete] = useState('')
  const [isAuthenticate, setIsAuthenticate] = useState(false)
  const [loading, setLoading] = useState(false)


  useEffect(() => {
    const loginService = new LoginService()
    const checkUser = async () => {
      try {
        await loginService.checkLoggedUser()
        setIsAuthenticate(true)
      } catch (e) {
        setIsAuthenticate(false)
        setModalMessage(e.message)
        setModalOpen(true)
      }
    }
    checkUser()
  }, [])

  const closeModal = () => {
    if (modalOpen) {
      setModalOpen(false)
      navigate('/login')
    } else {
      setIdToDelete('')
      setConfirmationModalOpen(false)
    }
  }

  const deleteConfirmation = (id, name) => {
    setModalMessage('Tem certeza que deseja excluir o cliente ' + name + '?')
    setConfirmationModalOpen(true)
    setIdToDelete(id)
  }

  const deleteCustomer = async () => {
    setLoading(true)
    try {
      await customerService.delete(idToDelete)
    }finally {
      setConfirmationModalOpen(false)
      setLoading(false)
      navigate(0)
    }
  }

  useEffect(() => {
    const getCustomers = async () => {
      setLoading(true)
      const data = await customerService.fectchAll()
      setCustomers(data)
      setLoading(false)
    }

    getCustomers()
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <>
      <div className="form-header">
        <Link to="/admin/" className="back-section">
          <img src={voltar} alt="voltar" />
        </Link>
        <h3>EDITAR CLIENTE</h3>
      </div>
      <div className="form-container">
        {
          !!loading ? (
            <div className="loading-container">
              <ReactLoading className="loading" type="spin" />
            </div>
          ) : (
            <>
                <div className="list">
                  {
                    customers.length === 0 ? (
                      <p style={{ display: 'flex', justifyContent: 'flex-start', fontSize: '16px' }}>Nenhum cliente cadastrado.</p>
                    ) : (
                      customers.map(customer => (
                        <table style={{width: '100%'}}>
                          <tbody>
                            <td key={customer.id} className="list-item" onClick={() => navigate("/admin/cliente/" + customer.id)}>
                              {customer.name.toUpperCase()}
                            </td>
                            <td style={{width: '20%'}}>
                              <img src={excluir} alt="excluir" className="trash" onClick={() => deleteConfirmation(customer.id, customer.name)} />
                            </td>
                          </tbody>
                        </table>
                      ))
                    )
                  }
                </div>
                <Modal
                  isOpen={confirmationModalOpen}
                  onRequestClose={closeModal}
                  style={{
                    content: {
                      height: '100px',
                      maxWidth: '300px',
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      // margin: 'auto'
                    }
                  }}
                >
                  {modalMessage}
                  <div className="two-buttons-modal-section">
                    <button onClick={deleteCustomer} className="modal-button">OK</button>
                    <button onClick={closeModal} className="cancel-button">Cancelar</button>
                  </div>
                </Modal>
            </>   
          )
        }
      </div>
      <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        style={{
          content: {
            height: '100px',
            maxWidth: '300px',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            // margin: 'auto',
          }
        }}
      >
        {modalMessage}
        <button onClick={closeModal} className="modal-button">OK</button>
      </Modal>
    </>
  )
}