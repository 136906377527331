import axios from "axios";

export default class CustomerService {
  async fectchAll() {
    try {

      const customers = await axios.get(`${process.env.REACT_APP_API_HOST}/customers`)
      return customers.data
    } catch(e) {
      return []
    }
  }

  async fetchAllByCategoryId(category_id) {
    try {
      const customers = await axios.get(`${process.env.REACT_APP_API_HOST}/customers/category/${category_id}`)
      return customers.data
    } catch (e) {
      return []
    }
  }

  async create(name, description, contact, logo, categories) {
    try {
      const token = await localStorage.getItem('SESSION_TOKEN')
      const result = await axios.post(`${process.env.REACT_APP_API_HOST}/customers`, {
        name,
        description,
        contact,
        logo,
        categories
      }, {
        headers: {
          'Authorization': token,
          'Content-Type': 'multipart/form-data'
        }
      })

      return result.data
    } catch (e) {
      throw new Error(e.message)
    }
  }

  async update(id, name, description, contact, logo=null, categories) {
    try {
      const token = await localStorage.getItem('SESSION_TOKEN')
      let url = `${process.env.REACT_APP_API_HOST}/customers/${id}`
      let headers = {
        'Authorization': token
      }

      if (!!logo) {
        url = `${process.env.REACT_APP_API_HOST}/customers/icon/${id}`
        headers = {
          ...headers,
          'Content-Type': 'multipart/form-data'
        }
      }

      const result = await axios.put(url, {
        id,
        name,
        description,
        contact,
        logo,
        categories
      }, {
        headers: headers
      })

      return result.data
    } catch (e) {
      throw new Error(e.message)
    }
  }

  async getById(id) {
    try {
      const customer = await axios.get(`${process.env.REACT_APP_API_HOST}/customers/${id}`)
      return customer.data
    } catch (e) {
      throw new Error(e.message)
    }
  }

  async delete(id) {
    try {
      const token = await localStorage.getItem('SESSION_TOKEN')
      return await axios.delete(`${process.env.REACT_APP_API_HOST}/customers/${id}`, {
        'headers': {
          'Authorization': token
        }
      })
    }catch(e) {
      throw new Error(e.message)
    }
  }
}