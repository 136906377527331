import axios from "axios";

export default class CategoriesService {
  async fectchAll() {
    try {

      const categories = await axios.get(`${process.env.REACT_APP_API_HOST}/categories`)
      return categories.data
    } catch(e) {
      return []
    }
  }

  async create(name, icon, type) {
    try {
      const token = await localStorage.getItem('SESSION_TOKEN')
      const result = await axios.post(`${process.env.REACT_APP_API_HOST}/categories`, {
        name,
        icon,
        type,
      }, {
        headers: {
          'Authorization': token,
          'Content-Type': 'multipart/form-data'
        }
      })

      return result.data
    }catch(e) {
      throw new Error(e.message)
    }
  }

  async update(id, name, icon=null, type) {
    try {
      const token = await localStorage.getItem('SESSION_TOKEN')
      let url = `${process.env.REACT_APP_API_HOST}/categories/${id}`
      let headers = {
        'Authorization': token
      }

      if(!!icon) {
        url = `${process.env.REACT_APP_API_HOST}/categories/icon/${id}`
        headers = {
          ...headers,
          'Content-Type': 'multipart/form-data'
        }
      }

      const result = await axios.put(url, {
        id,
        name,
        icon,
        type,
      }, {
        headers: headers
      })

      return result.data
    } catch (e) {
      throw new Error(e.message)
    }
  }

  async getById(id) {
    try {
      const category = await axios.get(`${process.env.REACT_APP_API_HOST}/categories/${id}`)
      return category.data
    } catch(e) { 
      throw new Error(e.message)
    }
  }

  async delete(id) {
    try {
      const token = await localStorage.getItem('SESSION_TOKEN')
      return await axios.delete(`${process.env.REACT_APP_API_HOST}/categories/${id}`, {
        'headers': {
          'Authorization': token
        }
      })
    } catch (e) {
      throw new Error(e.message)
    }
  }
}