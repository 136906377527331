import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Modal from 'react-modal'
import { Link, useNavigate } from "react-router-dom";
import LoginService from "../services/LoginService";
import "./css/MainAdmin.css"
import "./css/global.css"

export default function MainAdmin() {
  const navigate = useNavigate()
  const [modalOpen, setModalOpen] = useState(false)
  const [modalMessage, setModalMessage] = useState("")
  const [isAuthenticate, setIsAuthenticate] = useState(false)

  useEffect(() => {
    const loginService = new LoginService()
    const checkUser = async () => {
      try{
        await loginService.checkLoggedUser()
        setIsAuthenticate(true)
      } catch(e) {
        setIsAuthenticate(false)
        setModalMessage(e.message)
        setModalOpen(true)
      }
    }
    checkUser()
  },[])

  const closeModal = () => {
    setModalOpen(false)
    navigate('/login')
  }

  return (
    isAuthenticate ? (
      <div className="main">
        <Link to="/admin/categoria" className="button">
          CADASTRAR CATEGORIA
        </Link>
        <Link to="/admin/categoria/editar" className="button">
          EDITAR CATEGORIA
        </Link>
        <Link to="/admin/cliente" className="button">
          CADASTRAR CLIENTE
        </Link>
        <Link to="/admin/cliente/editar" className="button">
          EDITAR CLIENTE
        </Link>
      </div>
    ) : (
        <Modal
          isOpen={modalOpen}
          onRequestClose={closeModal}
          style={{
            content: {
              height: '100px',
              maxWidth: '400px',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              margin: 'auto'
            }
          }}
        >
          {modalMessage}
          <button onClick={closeModal} className="modal-button">OK</button>
        </Modal>
    )
  )
}