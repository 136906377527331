import React, { useState } from "react"
import ReactLoading from 'react-loading'
import "./css/Form.css"
import "./css/global.css"
import Modal from 'react-modal'
import voltar from "../images/voltar-icon.png"
import excluir from '../images/excluir-icon.png'
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useEffect } from "react"
import CategoriesService from "../services/CategoryService"
import LoginService from "../services/LoginService"
import ReactSelect from "react-select"

const CategoryType = {
  DELIVERY: 1,
  SERVICE: 2,
}

export default function AddEditCategory({action = 'edit | add'}) {
  const [categoryData, setCategoryData] = useState('')
  const [categoryFormData, setCategoryFormData] = useState({
    name: '',
    icon: '',
    type: 1,
    imagePreview: ''
  })
  const [error, setError] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [modalMessage, setModalMessage] = useState("")
  const [isAuthenticate, setIsAuthenticate] = useState(false)
  const [loading, setLoading] = useState(false)

  const categoryService = new CategoriesService()
  const { id } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {    
    const loginService = new LoginService()
    const checkUser = async () => {
      try {
        await loginService.checkLoggedUser()
        setIsAuthenticate(true)
      } catch (e) {
        setIsAuthenticate(false)
        setModalMessage(e.message)
        setModalOpen(true)
      }
    }

    checkUser()

    const getCategoryData = async (id) => {
      try {
        setLoading(true)
        const data = await categoryService.getById(id)
        setCategoryData(data)
        setCategoryFormData({
          ...data,
          imagePreview: `${process.env.REACT_APP_IMAGE_HOST}/${data.icon}` 
        })
        setLoading(false)
      }catch(e) {
        setError(e.message)
      }
    }

    if(action === 'edit' || !!id) {
      getCategoryData(id)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const closeModal = () => {
    setModalOpen(false)
    navigate('/login')
  }

  const handleSubmit = async (e) => {
    e.preventDefault()    
    try {
      setLoading(true)
      if(!id) {
        await categoryService.create(categoryFormData.name, categoryFormData.icon, categoryFormData.type)
      } else {
        await categoryService.update(id, categoryFormData.name, !!categoryFormData.icon?.lastModified ? categoryFormData.icon : null, categoryFormData.type)
      }
      setLoading(false)
      navigate('/admin/', {
        replace: true,
      })
    } catch(e) {
      if(loading)
        setLoading(false)
      setError(e.message)
    }
  }

  const onChangeCategoryName = (e) => {    
    setCategoryFormData((prevState) => ({
      ...prevState,
      name: e.target.value
    }))
  }

  const onIconChange = (e) => {
    //pega o tamanho do arquivo e transforma em kb
    const fileSize = e.target.files[0].size / 1000
    if(fileSize > 60) {
      alert('O ícone não pode ser maior que 60kb. Seu ícone tem '+ fileSize + 'Kb.')
      document.getElementById('icon').value = ''
    } else {
      setCategoryFormData((prevState) => ({
        ...prevState,
        icon: e.target.files[0],
        imagePreview: URL.createObjectURL(e.target.files[0])
      }))
    }
  }

  const onChangeCategoryType = (e) => {
    setCategoryFormData((prevState) => ({
      ...prevState,
      type: e.value
    }))
  }

  const handleDeleteIcon = () => {
    setCategoryFormData((prevState) => ({
      ...prevState,
      icon: null,
      imagePreview: null
    }))
    setCategoryData((prevState) => ({
      ...prevState,
      icon: ''
    }))
    document.getElementById('icon').value = ''
  }  

  return (
    isAuthenticate ? (
      <>
        <div className="form-header">
          <div
            className="back-section"
            onClick={() => navigate(!!id ? '/admin/categoria/editar' : '/admin', { state: { categoryType: location.state?.categoryType ?? 'delivery'}})}
          >
            <img src={voltar} alt="voltar" />
          </div>
          <h3>
            {
              action === 'edit' ? 'EDITAR CATEGORIA' : 'CADASTRAR CATEGORIA'
            }
          </h3>
        </div>
        <div className="form-container">
          {
            !!loading && (
              <div className="loading-container">
                <ReactLoading className="loading" type="spin" />
              </div>
            )
          }
          <form method="post" onSubmit={handleSubmit}>
            <div className="input-section">
              <p>CATEGORIA</p>
              <input type="text" name="name" id="name" value={action === 'edit' ? categoryFormData.name : undefined} onChange={onChangeCategoryName} required />
            </div >
            <div className="input-section">
              <p className="p-icon">ÍCONE</p>
              <label htmlFor="icon">ANEXAR</label>
              <input type="file" name="icon" id="icon" onChange={onIconChange} accept="image/png, image/jpeg, image/jpg, image/ico" />
              {
                (categoryData.icon || categoryFormData.icon) && (
                  <>
                    <div className="img-selected">
                      <img
                        src={categoryFormData.imagePreview}
                        alt={`icon-${categoryFormData.name}`}
                        className="upload-icon"
                      />
                      <img src={excluir} className="deleteIcon" alt="excluir-icon" onClick={handleDeleteIcon} />
                    </div>
                  </>
                )
              }
            </div>
            <div className="input-section">
              <p>TIPO</p>
              <ReactSelect
                closeMenuOnSelect={false}
                defaultValue={{
                  value: action === 'edit' ? categoryFormData.type : 1,
                  label: categoryFormData.type === CategoryType.DELIVERY ? 'DELIVERY' : 'PRESTAÇÃO DE SERVIÇOS'
                }}
                options={[
                  {
                    value: 1,
                    label: 'DELIVERY'
                  },
                  {
                    value: 2,
                    label: 'PRESTAÇÃO DE SERVIÇOS'
                  }
                ]}
                styles={{
                  control: (styles) => ({ ...styles, width: '260px', marginBottom: '20px' }),
                }}
                onChange={onChangeCategoryType}
              />
            </div>
            <button type="submit" name="done" className="form-button">ENVIAR</button>
          </form >
          {
            !!error && (
              <p style={{ color: 'red' }}>
                {error}
              </p>
            )
          }
        </div >
      </>
    ) : (
      <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        style={{
          content: {
            height: '100px',
            maxWidth: '400px',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            margin: 'auto'
          }
        }}
      >
        {modalMessage}
        <button onClick={closeModal} className="modal-button">OK</button>
      </Modal>
    )
  )
}